/* Image collage widget fro game action shot display */

.collage-container {
    --cont-width: min(50%, 45em);
    display: grid;
    grid: repeat(20,1fr) / repeat(20, 1fr);

    flex: 3;

    /* width: var(--cont-width); */
    aspect-ratio: 16 / 9;

    gap: 0.5em;
    
    overflow: hidden;
}

.collage-img {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: lightgray;
    border-radius: min(1.5vw, 1em);

    opacity: 0;

    overflow: hidden;

    animation: 250ms linear 500ms collage-fade-in;
    animation-fill-mode: forwards;
}

.collage-img>img {
    margin: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;

    cursor: pointer;
}

.collage-setup {
    grid-area: span 20 / span 13;
}

.collage-inner {
    display: flex;
    flex: 20%;
    flex-direction: column;
    gap: 5px;
}

.collage-zoom {
    grid-area: span 20 / span 3;
    animation-delay: 650ms;
}

.collage-action {
    grid-area: span 12 / span 4;
    animation-delay: 800ms;
}

.collage-detail {
    grid-area: span 8 / span 4;
    animation-delay: 900ms;
}

.collage-fullview {
    display: none;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    z-index: 1000;

    background-color: #000a;
}

.collage-fullview-close {
    position: fixed;
    top: 3rem;
    left: 3rem;

    color: white;
    font-size: 2rem;

    background-color: #0005;
    border: none;
}

@keyframes collage-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media ( orientation: portrait ) {
    .collage-setup, .collage-zoom, .collage-action, .collage-detail {
        grid-area: span 10 / span 10;
    }

    .collage-container {
        aspect-ratio: auto;
    }
}