/* Stylesheet for about page */
.aboutpage-root {
    position: relative;
}

.aboutpage-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5rem 1fr;
    justify-content: center;

    margin: 4rem auto 2rem auto;

    font-family: var(--font-primary);

}

.aboutpage-photo {
    grid-area: span 2 / span 1;

    margin: auto;

    width: 400px;
    height: 600px;

    object-fit: cover;
    object-position: center;
}

.aboutpage-text > img {
    margin: auto;
}

.aboutpage-note {
    font-size: 0.8rem;
    text-align: center;
}

@media (orientation:portrait) {
    .aboutpage-content {
        display: initial;
        margin: 1rem auto;
    }

    .aboutpage-photo {
        width: 10rem;
        height: 14rem;

        margin: 0 1rem 1rem 1rem;

        float: left;

        padding-left: 0;
    }

    .aboutpage-text {
        margin-bottom: 1rem;
        padding: 0 1rem;
    }

    .aboutpage-note {
        margin: 0 1rem;
    }
}