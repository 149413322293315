/* Stylesheet for reel component */

.reel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 400px;
    height: 400px;

    margin: auto;
}

.reel-item {
    position: relative;
    height: 100%;
    aspect-ratio: 1;
    left: 0;

    background-color: orangered;

    transition: left 250ms;
}

.reel-image {
    position: relative;
    width: 100%;
    height: 100%;
    
    vertical-align: middle;
    font-style: italic;
}

@media (orientation:portrait) {
    .reel {
        width: 100%;
        height: 100svw;
    }
}