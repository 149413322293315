/* Stylesheet for contact info page */

.contactpage-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 2rem;
}

.contactpage-content {
    position: relative;

    width: 40rem;

    font-family: var(--font-primary);
}

.contactpage-content:nth-child(2) {
    font-size: 2rem;
}

.contactpage-content:nth-child(3) {
    margin-bottom: 2rem;

    color: grey;
    font-style: italic;
    text-indent: 2rem;
}

.contactpage-mail::before {
    content: "";

    position: absolute;
    inset: 0 0 0 0;
    z-index: -1;

    background-color: var(--color-accent);
    border-radius: 30rem 2rem;
}

.contactpage-mail {
    position: relative;

    padding: 1rem 2rem;
    width: 50rem;
    
    font-size: 1.5rem;
    text-align: center;
    color: black;
}

.contactpage-mail > p:nth-child(1) {
    font-size: 1rem;
}

.contactpage-mail > p:nth-child(2) {
    background-color: white;

    width: fit-content;
    margin: 1rem auto 0 auto;

    border: 1rem solid white;
    border-radius: 1rem;
}

@media (orientation:portrait) {
    .contactpage-header {
        font-size: 1.8rem;
    }

    .contactpage-content {
        width: 100%;
    }
 
    .contactpage-mail::before {
        border-radius: 2rem;
    }
    
    .contactpage-mail {
        width: 100%;
        padding: 1rem 0;
    }
    
    .contactpage-mail > p:nth-child(1) {
        font-size: 1rem;

        margin-bottom: 0.5rem;
    }
    
    .contactpage-mail > p:nth-child(2) {
        margin: auto;

        font-family: var(--font-primary);
        font-size: 1rem;

        border: 0.5rem solid white;
    }
}