.homepage-content {
    position: relative;
    margin: 0 auto 2rem auto;
}

.homepage-curtain-container {
    display: flex;
    justify-content: space-between;

    position: absolute;
    inset: 0 0 0 0;
    height: 432px;

    z-index: 100;

    border: 0 solid black;
    border-bottom-width: 0.5rem;

    pointer-events: none;
}

.homepage-curtain {
    width: 232px;
    height: 100%;

    background-image: url("../images/red_curtain.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.homepage-curtain:last-child {
    scale: -1 1;
}

.homepage-carousel-container {
    position: relative;
    width: 100%;

    background-image: url("../images/homepage_bg.png");
    background-size: cover;
}

.homepage-skip-nav {
    position: absolute;
    left: -250px;
    top: 4rem;
    z-index: 1000;

    padding: 1rem;
    
    font-family: var(--font-primary);

    background-color: var(--color-primary);
    border-radius: 0 50px 50px 0;
    box-shadow: 0.5rem 0.5rem 0.5rem #000a;

    transition: left 250ms;
}

.homepage-skip-nav:focus {
    left: 0px;
}

.homepage-quicklinks {
    font-size: 1.5rem;
}

.homepage-story {
    position: relative;
    
    margin: 7rem 0 4rem 0;

    font-family: var(--font-primary);
}

.homepage-story::before {
    content: "";

    position: absolute;
    top: -3rem;
    left: 0;
    width: 100%;
    height: 2rem;

    background-color: var(--color-primary);
}

.homepage-story a {
    position: relative;
    font-family: var(--font-secondary);
    font-size: 1rem;
}

.homepage-story a::before {
    content: "";
    position: absolute;
    inset: -2px 0 -2px 0;
    width: 0;
    z-index: -1;
    
    border: 0 solid black;
    border-bottom-width: 3px;

    transition: width 250ms ease-out;
}

.homepage-story a:hover::before {
    width: 100%;
}

.homepage-sitemap {
    list-style: none;

    padding: 0;
    
    font-size: 0.8rem;
}

.homepage-sitemap > li {
    display: inline;

    padding: 0 1rem;
    
    border: 0 solid black;
    border-left-width: 2px;
}

.homepage-sitemap > li:nth-child(1) {
    border: none;
    padding-left: 0;
}

.homepage-banner {
    width: 100%;
    height: 5rem;

    background-color: var(--color-primary);
    mask: url("../images/wavy_banner.png");
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: 0 -3rem;
}

.homepage-splash {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 3fr;
    justify-content: center;
    align-items: center;

    margin: 3rem 0;
    padding: 2rem 1rem;
    height: 20rem;

    border: 0 solid var(--color-primary);
    border-top-width: 0.5rem;

    background-image: url("../images/homepage_swoosh.png");
    background-size: 100% calc(100% - 2rem);
    background-repeat: no-repeat;
    background-position: 0 1rem;
}

.homepage-splash > p {
    position: relative;

    isolation: isolate;

    grid-column-start: 2;

    padding: 0 1rem;

    background-color: white;
    border: 0.5rem solid white;
    border-radius: 2rem;
}

.homepage-splash-img {
    --var-hps-w: min(30vw, 325px);
    width: var(--var-hps-w);
    height: var(--var-hps-w);

    margin: auto;

    box-sizing: border-box;

    background-image: url("../images/homepage_splash.png");
    background-size: 100% 100%;
    border: 0.5rem solid white;
    border-radius: 50%;
}

.homepage-splash > a {
    isolation: isolate;
    display: block;

    position: relative;
    width: 22rem;
    height: fit-content;

    margin: 35% 0 0 0;

    text-align: center;

    background-color: var(--color-primary);
    border: 2rem solid var(--color-primary);
    border-radius: 3rem;

    transition: border-radius 200ms;
}

.homepage-splash > a:hover {
    border-radius: 1rem;
}

@media (orientation:portrait) {
    .homepage-carousel-container {
        width: 100vw;
        
        margin: auto -2rem;

        background-color: var(--color-accent);
        background-image: none;
        border: none;
    }

    .homepage-curtain {
        display: none;
    }

    .homepage-curtain-container {
        border: none;
    }

    h2 {
        overflow: hidden;
    }

    .homepage-banner {
        display: none;
    }

    .homepage-splash {
        display: flex;
        flex-direction: column;

        padding: 0;

        background-image: none;
    }

    .homepage-splash > p {
        margin: 0;

        font-size: 1rem;
        text-align: center;
    }

    .homepage-splash > p::before {
        display: none;
    }

    .homepage-splash-img {
        margin:0;
        width: 100%;

        flex: 2;

        background-size: cover;
        background-position: 0 50%;
        border-radius: 0;
    }

    .homepage-splash > a {
        width: 100%;
        margin: 2rem 0 0 0 ;
        box-sizing: border-box;

        font-size: 1rem;

        border-width: 1.5rem;
    }
}