/* Stylesheet for game info display page */

body {
    background-color: var(--color-background);
}

.gamepage-root {
    position: relative;
}

.gamepage-body {
    margin: 4rem auto 2rem auto;

    opacity: 1;

    /* animation: gamepage-fade-in 150ms linear forwards; */
}

@keyframes gamepage-fade-in {
    from { opacity: 0; }
    to {opacity: 1;}
}

.gamepage-title {
    position: relative;

    margin: 1rem 0;

    font-size: 3rem;

    /* background-color: yellowgreen; */
    /* border-radius: 50px; */
    /* background-image: url("../../../public/images/title_banner.png"); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    overflow: hidden;
}

/*.gamepage-title::before {
    content: "";

    position: absolute;
    top: -100px;
    width: 500px;
    height: 500px;
    z-index: -1;

    box-shadow:
     0 0 0 10px white,
     0 0 0 30px yellowgreen,
     0 0 0 50px white,
     0 0 0 65px yellowgreen,
     0 0 0 95px white,
     0 0 0 105px yellowgreen;

    rotate: -15deg;

    background-color: yellowgreen;
    border-radius: 100px;
 
    transform-origin: 25% 50%;
    rotate: -2deg;
} */

.gamepage-top {
    position: relative;

    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 2rem 0;

    /* background-color: lightgray; */
}

.gamepage-bottom {
    width: 100%;

    /* background-color: cadetblue; */

    font-family: var(--font-primary);
}

.gamepage-headline {
    display: inline;
    
    padding: 0 0.5rem 0 0;
    position: relative;

    font-family: var(--font-primary);
    font-size: 3rem;
}

.gamepage-content {
    display: inline;
    text-indent: 5rem;
}

.gamepage-downloads {
    text-decoration: underline;
}

.gamepage-credits {
    font-style: italic;

    margin: 1rem 0;
}

.gamepage-contact-us {
    width: 100%;

    font-size: 0.9rem;
    font-style: italic;
    text-decoration: underline;
    text-align: right;
}

.gamepage-no-data {
    width: 100%;

    font-size: 2rem;
    text-align: center;
}

.gamepage-no-data p {
    margin: 4rem auto;
}

.gamepage-no-data button {
    font-family: inherit;
    font-size: 1rem;

    background-color: var(--color-primary);
    border: 1rem solid var(--color-primary);
    border-radius: 50px;

    transition: box-shadow 250ms;
}

.gamepage-no-data button:hover {
    box-shadow: 0 0 0 3px var(--color-accent);
}

.gamepage-bottom > p:last-child {
    font-style: italic;
}

.gamepage-back {
    margin: 0;

    font-size: 1rem;
    font-family: var(--font-primary);
}

@media (orientation:portrait) {
    .gamepage-body {
        margin: 2rem auto;
    }

    .gamepage-top {
        flex-direction: column;
    }

    .gamepage-title {
        font-size: 2rem;
    }
}