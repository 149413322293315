/* Stylesheet for Footer */

.footer {
    position: absolute;
    top: max(100%, calc(100vh - 3rem));
    width: 100%;
    height: 1rem;
    margin: 1rem 0 0 0;
    padding: 0.5rem 0;

    font-size: 0.75rem;
    text-align: center;

    background-color: var(--color-primary);

    overflow: hidden;
}

.footer-return {
    position: absolute;
    top: 0;
    right: 1rem;

    margin-top: 0.5rem;

    cursor: pointer;
}

@media (orientation:portrait) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        font-size: 0.5rem;
        text-align: left;
        text-indent: 1rem;
    }
}