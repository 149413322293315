/* Stylesheet for carousel component */

.carousel {
    position: relative;

    padding: 1rem 0;

    width: 100%;
    height: 400px;

    overflow: hidden;
}

@media (orientation:portrait) {
    .carousel {
        width: 100vw;
        height: 100vw;
        padding: 0;
    }
}