/* Stylesheet for game stats display */
.game-stats-container {
    --spacing: 0.5em;

    display: grid;
    justify-content: center;
    flex: 2;

    position: relative;
    aspect-ratio: 1.18519;
    
    /* padding: var(--spacing); */
    grid: 1fr 1fr 1fr / 1fr 1fr 1fr;
    gap: var(--spacing);

    /* background: linear-gradient(-45deg, var(--color-accent) 50%, transparent 51% 64%, var(--color-accent) 65% 72%, transparent 73% 79%, var(--color-accent) 80% 85%, transparent 86%); */
    border-radius: min(1.5vw, 1em);
}

.game-stats-container::before {
    content: "";
    position: absolute;
    
    width: 100%;
    height: 100%;
    z-index: -1;
    
    /* background-image: linear-gradient(0deg, var(--color-accent) 40%, transparent 40% 46.5%, var(--color-accent) 46.5% 59%, transparent 59% 71%, var(--color-accent) 71% 79.3%, transparent 79.3% 95.8%, var(--color-accent) 95.8%); */
    /* background-image: linear-gradient(0deg, var(--color-accent) 35%, transparent 35% 43%, var(--color-accent) 43% 58%, transparent 58% 68%, var(--color-accent) 68% 70%, transparent 70%); */
    /* background-color: var(--color-accent); */
    background-color: var(--color-accent);
    border-radius: min(1.5vw, 1em);

    mask: url("../images/gamestats_bg.png");
    mask-size: cover;
    mask-position: left bottom;
}

.game-stats-genres {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    grid-column: span 3;
}

.game-stats-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    position: relative;
    inset: 0 0 0 0;
    
    font-size: min(2.6vw, 30px);
    
    overflow: hidden;
}

.game-stats-label > svg {
    width: 60%;
    aspect-ratio: 1;
}

.game-stats-icon {
    justify-self: center;
    align-self: center;
    
    width: 100%;
    height: 100%;
    
    background-image: url("../images/game_stats_arrow.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
    overflow: hidden;
}

.game-stats-icon > img {
    width: 100%;
    height: 100%;
}

.game-stats-players {
    grid-column: span 2;
    
    font-size: min(1.75vw, 20px);
}

.game-stats-duration {
    font-size: min(2vw, 23.25px);
}

/*
Purchase button 
*/

.game-stats-purchase-container {
    --punch: 7%;
    
    isolation: isolate;
    
    position: relative;
    grid-column: span 2;
    
    font-size: 1.25rem;
    
    cursor: pointer;
    
    transition: 150ms rotate;
}

.game-stats-purchase-container:hover, .game-stats-purchase-container:has( > a:focus ) {
    rotate: -3deg;
}

.game-stats-purchase-container:has( > a:focus ) {
    outline: 3px dashed lightskyblue;
}

.game-stats-purchase {
    --s-punch: calc(var(--punch) - 3%);
    
    display: flex;
    align-items: center;
    
    font-size: 0.75rem;
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background: radial-gradient(circle at 2.5% 50%, transparent var(--s-punch), black calc(var(--s-punch) + 0.5%));
    -webkit-mask-image: radial-gradient(circle at 97.5% 50%,
    transparent var(--s-punch),
    black calc(var(--s-punch) + 0.5%));
    mask-image: radial-gradient(circle at 97.5% 50%,
    transparent var(--s-punch),
    black calc(var(--s-punch) + 0.5%));
}

.game-stats-purchase>p {
    position: absolute;
    
    color: white;
    text-align: left;
    font-size: min(1.75vw, 20px);
    
    padding-left: 15%;
    
    z-index: 2;
}

.game-stats-purchase::before {
    content: "";
    
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: calc(100% - 0.5rem);
    height: calc(100% - 0.5rem);
    
    z-index: 1;
    
    background: radial-gradient(circle at 0% 50%, transparent var(--punch), var(--color-primary) calc(var(--punch) + 0.5%));
    -webkit-mask-image: radial-gradient(circle at 100% 50%,
    transparent var(--punch),
    black calc(var(--punch) + 0.5%));
    mask-image: radial-gradient(circle at 100% 50%,
    transparent var(--punch),
    black calc(var(--punch) + 0.5%));
}

.game-stats-purchase::after {
    content: "";
    
    position: absolute;
    inset: 15% 15% auto auto;
    height: 70%;
    aspect-ratio: 1;
    z-index: 3;
    
    background-image: url("../images/GC_logo.png");
    background-size: cover;
    border-radius: 50%;
}

@media (orientation:portrait) {
    .game-stats-container {
        width: 100%;
    }

    .game-stats-label {
        font-size: min(5vw, 30px);
    }

    .game-stats-purchase > p {
        font-size: min(3.5vw, 1rem);
    }
}