.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    padding: 0.5rem 1rem;
    z-index: 1000;

    background-color: var(--color-primary);

    overflow: visible;
    
}
/*
.header-title {
    margin: 0 1rem 0 0;
    flex: 1fr;
    
    text-align: left;
    font-family: var(--font-primary);
    font-size: 1.75rem;
} */

.header-spacer {
    flex: auto;
}

.header-logo {
    height: 1.8rem;

    margin-right: 2rem;
}

@media (orientation:portrait) {
    /* .header-title {
        flex: 1fr;
        font-size: 0.85rem;
    } */
    
    .header-spacer {
        display: none;
    }

    .header {
        display: grid;
        grid-template-columns: 50% 1fr;
        grid-template-rows: 1fr 1fr;
        justify-content: center;
    }

    .header-logo {
        height: 1rem;
        margin: 0;
    }
}