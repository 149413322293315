/* Stylesheet for genre icons */

.genre-icon {
    position: relative;
    margin: auto;

    max-width: 5rem;

    /* background-color: lightgray; */
    background-image: radial-gradient(white 60%, lightgrey 62%);
    border-radius: 50%;
}

.genre-icon-image {
    width: 80%;
    height: 80%;

    aspect-ratio: 1;

    margin: 10%;

    opacity: 0.6;

    object-fit: contain;
}

.genre-label {
    position: absolute;
    width: 100%;
    height: 1rem;
    bottom: -1rem;

    line-height: 1rem;
    text-align: center;
}