/* Stylesheet for menu widget */

:root {
    --menu-height: 8.65rem;
    --menu-mobile-height: 13.5rem;
}

.menu-container {
    position: relative;
    width: 8rem;
    height: 2rem;

    border: 1px solid black;
    border-radius: 5px;
}

.menu-content {
    position: absolute;
    width: 100%;
    height: 2rem;

    background-color: orange;
    border-radius: 5px;

    text-align: center;

    overflow: hidden;

    transition: height 200ms;
}

.menu-content:hover {
    height: var(--menu-height);
    filter: drop-shadow(5px 5px 5px black);
}

.menu-item-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--menu-height); 

    background-color: var(--color-primary);
}

.menu-item {
    position: relative;

    display: block;
    margin: 0.5rem;
    
    font-family: 'Rubik Mono One', monospace;
    font-size: 0.75rem;
    -webkit-text-fill-color: black;
    
    border: none;
    background-color: transparent;

    cursor: pointer;

    transition: rotate 150ms, background-color 150ms;

    z-index: 1;
}

.menu-item:hover {
    rotate: -5deg;
}

a:first-child > .menu-item {
    font-size: 1rem;
    transition: rotate 150ms;
}

.menu-item::before {
    content: "";

    position: absolute;
    inset: 25% 15% 25% 15%;

    background-color: var(--color-accent);
    box-shadow: 0 0 5px 5px var(--color-accent);

    z-index: -1;

    rotate: 0;

    opacity: 0;

    transition: rotate 150ms, opacity 150ms, scale 150ms;
}

.menu-item:hover::before {
    rotate: 15deg;
    opacity: 0.65;
}

.menu-item:active::before {
    scale: 0.75;
}

.menu-current {
    position: relative;

    display: block;
    margin: 0.5rem;
    
    font-family: 'Rubik Mono One', monospace;
    font-size: 0.75rem;

    z-index: 1;
}

.menu-current:first-child{
    font-size: 1rem;
}

.menu-content:has(a:focus),.menu-content:hover:has(a:focus) {
    height: var(--menu-height);
    filter: drop-shadow(5px 5px 5px black);
}

@media (orientation:portrait) {
     .menu-container {
        margin: auto;
        width: 8rem;
    }

    .menu-content {
        --menu-height: var(--menu-mobile-height);
    }

    .menu-item,.menu-current {
        margin: 1.5rem auto;
    }
}