/* Stylesheet for search bar */

.search-form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.search-bar {
    padding: 0 5px;

    height: 1.5rem;

    background-color: #0003;
    border: none;
}

.search-bar:focus {
    background-color: #fff7;
}

.search-bar::placeholder {
    color: #0008;
}

.search-button {
    width: 1.75rem;
    height: 1.75rem;

    background-color: transparent;
    border: none;
    
    cursor: pointer;
}

.search-button > img {
    width: 1.5rem;
    height: 1.5rem;
}

@media (orientation:portrait) {
    .search-form {
        padding-top: 0.5rem;
        grid-area: auto / span 2;
    }

    .search-bar {
        width: 80%;
    }
}