/* Stylesheet for game card display */

.game-card {
    display: grid;
    grid: 1fr 1fr 1fr / 1fr 1fr 1fr;
    gap: 2px;
    
    isolation: isolate;
    
    padding: 1.5rem 0.75rem;
    
    width: 200px;
    aspect-ratio: 1;
    
    background-color: white;
    border-radius: 1rem;
    border: 2px solid lightgrey;
    
    cursor: pointer;
  }
  
  .game-card:active {
    background-color: lightgrey;
  }
  
  .game-card-image {
    grid-area: 1/1/3/3;
    
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 2;
    
    overflow: hidden;
    
    mask: linear-gradient(black 70%, transparent 90%);
  }
  
  .game-card-image > img {
    width: 90%;
    height: 90%;
  }
  
  .game-card-body {
    position: relative;
    z-index: 0;
    
    grid-area: 2/1/4/4;
    
    background-color: yellowgreen;
    border-radius: 1rem;
  }
  
  .game-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: relative;
    
    padding-left: 0.5rem;
    
    grid-area: 3/1/4/3;
    
    z-index: 2;
  }
  
  .game-card-title > p {
    margin: 0;
    padding: 0;
    
    font-size: 1rem;
  }
  
  .game-card-shout {
    position: relative;
    
    grid-area: 3/3/4/4;
    
    z-index: 2;
    
    color: red;
  }
  
  .game-card-shout > p {
    --shout-size: 1rem;

    position: absolute;
    top: 50%;
    left: 50%;
    
    margin: 0;
    padding: 0;
    
    width: 20rem;
    height: var(--shout-size);
    
    font-size: var(--shout-size);
    text-align: center;
    font-family: "Rubik mono one", serif;
    
    translate: -50% -50%;
    rotate: -45deg;
    
    text-shadow:
      -1px 0 0 #000,
      1px 0 0 #000,
      0 -1px 0 #000,
      0 1px 0 #000;
  }
  
  .game-card:hover > .game-card-shout > p {
    animation: 400ms wiggle ease-in-out alternate infinite;
  }
  
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(10deg);
    }
  }
  
  .game-card-icon {
    grid-area: 2/3/3/4;
    
    margin: 5%;
    
    z-index: 1;
    
    background-color: white;
    background-image: url("../images/no_icon.png");
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
  }
  
  @media (orientation:portrait) {
    .game-card {
      width: 200px
    }
  }