/* Stylesheet for library page */

.library-root {
    overflow: hidden;
}

.library-content {
    margin: 2rem auto 2rem auto;
}

.library-content > div {
    text-align: center;
}

.library-controls {
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 1rem 0;

    /* background-color: aqua; */
}

.library-controls label {
    font-style: italic;

    margin-right: 0.5rem;
}

.library-filter-btn {
    padding: 0.75rem 1rem;

    background-color: var(--color-primary);
    border: none;
    border-radius: 100% 20%;

    transition: border-radius 250ms;
}

.library-filter-btn:hover, .library-filter-btn:focus {
    border-radius: 20%;
}

.library-filter-btn:active {
    background-color: var(--color-accent);
}

.library-clear-btn {
    font-size: 1rem;
    text-decoration: underline;
}

.library-browser {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;

    padding: 2rem 0;

    border: 2px solid lightgrey;
    border-radius: 1rem;
}

.library-clear-search {
    display: none;

    margin: 1rem;
    padding: 0.5rem;

    background-color: var(--color-primary);
    border-radius: 1rem;
}

.library-clear-search:hover {
    background-color: var(--color-accent);
}

@media (orientation:portrait) {
    .library-controls {
        display: none;
    }

    .library-clear-search {
        display: block;
    }
}