/* Clown Around games app component stylesheet */

/*
font-family: 'Alice', serif;
font-family: 'Rubik Mono One', monospace;
font-family: 'Spline Sans Mono', monospace;
font-family: 'Yeseva One', serif;
*/

/* Global properties */
:root {
    /* FONTS */
    --font-primary: 'Alice', serif;
    --font-secondary: 'Rubik Mono One', monospace;

    /* COLORS */
    --color-primary: yellowgreen;
    --color-accent: #985BFE;
    --color-other: #dd3a13;
    --color-neutral: lightgray;
    --color-background: white;
}

/* id */

/* class */
.global-content-box {
    width: min(calc(100% - 4rem),1080px);
}

.global-loading {
    margin: 0 auto;

    color: lightgray;
    text-align: center;
    font-size: 5rem;

    rotate: -10deg;

    animation: global-loading-wiggle 500ms ease-in-out alternate infinite;
}

@keyframes global-loading-wiggle {
    from {
        rotate: -10deg;
    }

    to {
        rotate: 10deg;
    }
}
